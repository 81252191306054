import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/psdev/Documents/gitdev/workspace/working/myproject/hds25-gatby4-anndream-website-2022/site/src/components/layout.js";
import LeadParagraph from '../../components/LeadParagraph';
import Image from '../../components/Image';
import InternalLink from '../../components/InternalLink';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "patterns-overview",
      "style": {
        "position": "relative"
      }
    }}>{`Patterns overview`}<a parentName="h1" {...{
        "href": "#patterns-overview",
        "aria-label": "patterns overview permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h1>
    <LeadParagraph mdxType="LeadParagraph">
  Patterns are a collection of design principles and solutions. They consist of guidelines and component examples of how to to build pages for specific user flows.
    </LeadParagraph>
    <p>{`Patterns describe how `}<InternalLink href="/components/" mdxType="InternalLink">{`HDS components`}</InternalLink>{` are used together to build solutions that are coherent across the whole city.`}</p>
    <Image src="/images/patterns/patterns.svg" alt="The HDS Foundation page image with HDS and HKI texts and design system icons" style={{
      "width": "100%",
      "maxWidth": "650px",
      "margin": "var(--spacing-layout-2-xs) 0"
    }} viewable mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      